<template>
  <el-form ref="searchForm"   label-width="240" :inline="true">
	    <el-button type="primary" @click="addDept()">添加</el-button>
  </el-form>

  <el-table :data="dataList" row-key="id" border  ref="multipleTable">

	
	<el-table-column prop="name" label="项目名称" ></el-table-column>

	
	<el-table-column fixed="right" width="150px" >
	  <template v-slot="scope" >
			 <el-button  icon="el-icon-update" @click="updateDept(scope.row)" type="view" v-show="scope.row.level == 1">
				   修改
			  </el-button>
	  </template>
	</el-table-column>
	
	<el-table-column fixed="right" width="150px" >
	  <template v-slot="scope" >
			  <el-button  icon="el-icon-delete" @click="deleteDept(scope.row)" type="view" v-show="scope.row.level == 1">
			  				   删除
			   </el-button>
	  </template>
	</el-table-column>
  
  </el-table>
  
   <rms_dept_add @closerepairsView="closerepairsAdd"  v-if="deptDialogVisible"></rms_dept_add>
</template>
<script>
  //中文包
  //引入vue方法
  import { ElConfigProvider } from 'element-plus';
  import zhCn from "element-plus/lib/locale/lang/zh-cn";
  import rms_dept_add from './rms_dept_add.vue';
  export default {
    name: 'ZhProvider',
    setup(){
      let locale = zhCn
      return{
        locale
      }
    },
	data() {
	  let self=this;
	  return {
	    dataList:[],  
		deptDialogVisible:false,
	  }
	},

  /*启动后触发该方法*/
  created() {
	this.queryMembers()
  },
  methods: {
	  addDept:function(){
	  	  this.id = 0;
	  	  this.deptDialogVisible = true;
	  },
	  updateDept:function(item){
	  	  this.id = item.id;
		  this.item = item;
	  	  this.deptDialogVisible = true;
	  },
	  deleteDept:function(item){
	  	  this.$confirm('是否确认删除此部门。', '提示', {
	  	    confirmButtonText: '确定',
	  	    cancelButtonText: '取消',
	  	    type: 'warning'
	  	  }).then(() => {
	  	    this.sureDeleteDept(item);
	  	  }).catch(() => {});
	  },
	  sureDeleteDept:function(item){
		    var self = this;
		    var data = {};
		    data.cmd = "sureDeleteDept";
		    data.id = item.id;
		    this.$sknet.postJson(this.$skconf("met.surl"), data, function(data){
				self.$message("删除成功");
				this.queryMembers();
		  }, function(data) {
			  
		  }, self);
	  },
	  closerepairsAdd:function(){
	    this.deptDialogVisible = false;
	    this.queryMembers();
	  },
      queryMembers:function(){
		this.dataList = [];
		var req = {};
		req.cmd = "getDeptList";
		this.$sknet.postJson(this.$skconf("cla.surl"), req, function(res) {
		  this.dataList=res.datas.items;
		   this.deptDialogVisible = false;
		}, null, this);
    }
  },

  components: {
	  rms_dept_add
  },
}
</script>