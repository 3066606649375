<template>
  <el-dialog
      title="新增"
      v-model="performanceadd"
      width="50%"
      height="100px"
      center append-to-body @close='closeDialog'>
    <div class="el-dialog-div">
		
  <el-form   :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" label-height="100px" class="ruleForm">

    <el-form-item label="所属项目" prop="plotId" :rules="[
            { required: true, message: '请选择', trigger: 'blur' },
          ]"
    >
    
	  <el-select v-model="ruleForm.plotId" placeholder="请选择" style="width:240px" clearable >
	    <el-option v-for="data in optionsype" :key="data.plot_id" :label="data.plot_name" :value="data.plot_id"></el-option>
	  </el-select>
	  
    </el-form-item>

    <el-form-item label="部门名称" prop="name"  :rules="[
            { required: true, message: '请添写', trigger: 'blur' },
          ]"
    >
      <el-input  v-model="ruleForm.name"></el-input>
    </el-form-item>

    </el-form>


    <div class="clear"></div>
    </div>
    <div class="ucForm-group-ad">
      <div class="ucForm-group ucBtn-bg">
        <button class="ucBtn ucBtn-ok ucBtn-blue" v-on:click="commit('ruleForm')"><i class="iImg"></i>确定</button>
        <button class="ucBtn ucBtn-refresh ucBtn-gray" v-on:click="closeDialog" ><i
            class="iImg"></i>返回</button>
      </div>
    </div>
  </el-dialog>
 
</template>
<script>
export default {
  created () {
    this.performanceadd = true;
	console.log("进入编辑页面");
    this.getplotname();
  },
  data(){
    let self=this;
    return{
      position:'top',
      moneytype:false,
      ruleForm:{
        id:this.$parent.id,
		item:this.$parent.item,
        plotId:'',
        name:''
      },
	  optionsype:[],  //所属项目下拉
    }},

  methods: {
    amountchang:function(val){
      
    },
    closeDialog: function(){
      this.$emit('closerepairsView'); //通知父组件改变。
    },
    commit:function(ruleForm){

      this.$refs[ruleForm].validate((valid) => {

        if (valid) {
          var self = this;
          var data = {};
          data.cmd = 'insertRmsDept';
          data.id = this.ruleForm.id;   //
          data.plotId = this.ruleForm.plotId;
          data.name = this.ruleForm.name;
       
          this.$sknet.postJson(this.$skconf("met.surl"), data, function(data){
            self.$message("操作成功");
            self.closeDialog();
          }, function(data) {
            self.$message(data.reason);
          }, self);
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },

    //查询所有项目名称和ID的方法
    getplotname:function(){
      var self = this;
      self.options;
      var redata = {};
      redata.cmd = "getPmsName";
      this.$sknet.postJson(this.$skconf("met.surl"), redata, function(datas){
        this.optionsype=datas.datas.items;
		
		if(self.ruleForm.id > 0){
			console.log("--" + self.ruleForm.item.plotId);
			self.ruleForm.name = self.ruleForm.item.name;
			self.ruleForm.plotId = self.ruleForm.item.plotId + "";
		}
      }, function(data) {
        self.$message(data.reason);
      }, self);
    },
   


  },
  mounted(){

  },
  components: {
    
  },

}
</script>

<style scoped>

.el-dialog-div {
  height: 60vh;
  overflow: auto;
  Margin:5px;
}

</style>